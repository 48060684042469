<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2"
import $ from "jquery";
// import moment from 'moment';
import Cookies from "js-cookie";

/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Surat Keluar",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader
    },
    data() {
        return {
            title: "Surat Keluar",
            items: [
                {
                    text: "Home",
                    href: "/",
                },
                {
                    text: "Surat Keluar",
                    href: "/surat-masuk",
                },
                {
                    text: "Detail",
                    active: true,
                },
            ],
            // variable Page Table
            loadingTable: true,
            dataTable: [],
            pagingTable: [],
            showDataToTable: "",
            showDataFromTable: "",
            totalDataTable: "",
            currentTablePage: "",
            searchDataTable: "",
            id: this.$route.params.id,
            departemen: [],
            departemen_selected: "",
            departemen_selected_code: "",
            tipe_surat_keluar: [],
            tipe_surat_keluar_selected: "",
            tipe_surat_keluar_selected_kode: "",
            tipe_surat_keluar_selected_id: "",
            ad_nomorsurat: "",
            bulan_surat: "",
            tahun_surat: "",
            ad_sifatsurat: "",
            sifat_surat_selected: "",
            no_surat: "",
            bentuk_dokumen: [],
            bentuk_dokumen_selected: "",
            kategori_surat: [],
            kategori_surat_selected: "",
            isNoTelp: false,
            isKategoriSurat: false,
            isTeamLeader: false,
            isDivisi: false,
            team_leader: [],
            team_leader_selected: "",
            team_leader_selected_nama: "",

            EditNoSuratModal: false,
            ad_divisi: "",
            draft: "y",
            ad_lampiran: "",
            ad_lampiran_password: "",
            ad_tanggalsuratditerima: "",
            ad_tanggalsurat: "",
            ad_instansipengirim: "",
            ad_pic: "",
            ad_notelp: "",
            ad_tandatangan: "",
            ad_dikirim: "",
            ad_perihal: "",

            modalShowKode: false,
            showPasswordSurat: false,
            ad_bentukdokumen: "",
            ad_kategorisurat: "",
            status_message: "",
            status: "",


            showLampiran: false,
            //lampiran
            lampiran: [],
            lampiran_db: "",
        };
    },
    mounted() {
        this.getDepartemen();
        this.getTipeSuratKeluar();
        this.getKategoriSurat();
        this.getSuratKeluar();
        this.getUser();
        this.getLampiran();
    },
    methods: {
        getSuratKeluar() {
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
                allowOutsideClick: false,
            });

            let self = this;
            axios
                .get(
                    process.env.VUE_APP_BACKEND_URL_VERSION +
                    "api/arsip-dokumen/suratkeluar/detail?i=" +
                    self.id
                )
                .then((response) => {
                    var response_data = response.data;
                    if (response_data.code == 200) {
                        var arsip_dokumen = response_data.data.arsip_dokumen;
                        self.ad_nomorsurat = arsip_dokumen.ad_nomorsurat;
                        self.ad_tanggalsurat = arsip_dokumen.ad_tanggalsurat;
                        self.ad_tanggalsuratditerima = arsip_dokumen.ad_tanggalsuratditerima;
                        self.ad_tipesurat = arsip_dokumen.ad_tipesurat;
                        self.ad_instansipengirim = arsip_dokumen.ad_instansipengirim;
                        self.ad_sifatsurat = arsip_dokumen.ad_sifatsurat;
                        self.ad_perihal = arsip_dokumen.ad_perihal;
                        self.ad_lampiran = arsip_dokumen.ad_lampiran;
                        self.ad_lampiran_password = arsip_dokumen.ad_lampiran_password;
                        self.ad_dikirim = arsip_dokumen.ad_dikirim;
                        self.ad_pic = arsip_dokumen.ad_pic;
                        self.ad_tandatangan = arsip_dokumen.ad_tandatangan;
                        self.tipe_surat_keluar_selected_id = arsip_dokumen.ad_tsk_id;
                        self.ad_notelp = arsip_dokumen.ad_notelp;
                        self.ad_divisi = arsip_dokumen.ad_divisi;
                        self.ad_lampiran = arsip_dokumen.ad_lampiran;
                        self.ad_lampiran_password = arsip_dokumen.ad_lampiran_password;
                        self.ad_bentukdokumen = arsip_dokumen.ad_bentukdokumen;
                        self.ad_kategorisurat = arsip_dokumen.ad_kategorisurat;
                        self.status = arsip_dokumen.status;
                        self.status_message = arsip_dokumen.status_message;

                        // TIPE SURAT KELUAR
                        axios
                            .get(
                                process.env.VUE_APP_BACKEND_URL_VERSION +
                                "/api/master/tipe_surat_keluar?id=" +
                                arsip_dokumen.ad_tsk_id
                            )
                            .then((response) => {
                                var response_data = response.data;
                                var response_data_fix = response_data.list_data.data;
                                var response_data_fix_fix = response_data.list_data.data[0];
                                if (response_data.code == 200) {
                                    self.tipe_surat_keluar_selected = response_data_fix;
                                    self.tipe_surat_keluar_selected_kode = response_data_fix_fix.kode;
                                } else {
                                    //console.log("gagal load tipe surat keluar")
                                }
                            });

                        if (arsip_dokumen.ad_tsk_id != '2') {
                            self.isKategoriSurat = false;
                            self.isNoTelp = true;
                            self.isTeamLeader = true;
                            self.isDivisi = false;
                            self.getDepartemen();

                            self.bentuk_dokumen.push(
                                {
                                    key: 'surat',
                                    value: 'Surat',
                                },
                                {
                                    key: 'surat_dan_proposal',
                                    value: 'Surat dan Proposal',
                                },
                                {
                                    key: 'surat_dan_dokumen_pendukung_lainnya',
                                    value: 'Surat dan Pendukung Lainnya',
                                },
                            );
                        } else { //Is Divisi
                            self.isKategoriSurat = true;
                            self.isNoTelp = true;
                            self.isTeamLeader = false
                            self.isDivisi = true;
                            self.getDepartemenByTipeSuratKeluar();

                            self.bentuk_dokumen.push(
                                {
                                    key: 'surat',
                                    value: 'Surat',
                                },
                            );
                        }

                        // GET DEPARTEMENT
                        axios.get(process.env.VUE_APP_BACKEND_URL_VERSION + '/api/master/departemen?id=' + arsip_dokumen.ad_departemen).then((response) => {
                            var response_data = response.data;
                            var response_data_fix = response_data.list_data.data;
                            if (response_data.code == 200) {
                                self.departemen_selected = response_data_fix[0];
                                self.departemen_selected_code = response_data_fix[0].code;

                                //GET TEAM LEADER
                                axios
                                    .get(
                                        process.env.VUE_APP_BACKEND_URL_VERSION +
                                        "/api/master/user/pic?departemen_id=" +
                                        response_data_fix[0].id
                                    )
                                    .then((response) => {
                                        var response_data = response.data;
                                        if (response_data.code == 200) {
                                            self.team_leader = response_data.list_data.data;

                                            //GET TEAM LEADER SELECTED
                                            axios
                                                .get(
                                                    process.env.VUE_APP_BACKEND_URL_VERSION +
                                                    "/api/master/user/pic?id=" +
                                                    arsip_dokumen.ad_id_teamleader
                                                )
                                                .then((response) => {
                                                    var response_data = response.data;
                                                    var response_data_fix = response_data.list_data.data[0];
                                                    if (response_data.code == 200) {
                                                        self.team_leader_selected = response_data_fix;
                                                        self.team_leader_selected_nama = response_data_fix.name;
                                                    } else {
                                                        //console.log('gagal get team leader');
                                                    }
                                                });
                                        } else {
                                            //console.log('gagal get team leader');
                                        }
                                    });
                            } else {
                                //console.log("gagal load departemen")
                            }
                        });

                        // GET KATEGORI SURAT
                        if (arsip_dokumen.ad_kategorisurat_id != 0) {
                            axios
                                .get(
                                    process.env.VUE_APP_BACKEND_URL_VERSION +
                                    "/api/master/kategorisurat?id=" +
                                    arsip_dokumen.ad_kategorisurat_id
                                )
                                .then((response) => {
                                    var response_data = response.data;
                                    var response_data_fix = response_data.list_data.data;
                                    if (response_data.code == 200) {
                                        self.kategori_surat_selected = response_data_fix[0];
                                    } else {
                                        //console.log("gagal load kategori surat")
                                    }
                                });
                        }
                        var tanggal_surat = arsip_dokumen.ad_tanggalsurat.split('-');
                        self.bulan_surat = tanggal_surat[1];
                        self.tahun_surat = tanggal_surat[0];

                        if(self.ad_bentukdokumen != 'surat'){
                            self.showLampiran = true;
                        }

                        Swal.close();
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Gagal Load Data",
                        });
                    }
                });
        },

        getDepartemen() {
            let self = this;
            axios
                .get(
                    process.env.VUE_APP_BACKEND_URL_VERSION +
                    "/api/master/departemen?status=ENABLE"
                )
                .then((response) => {
                    var response_data = response.data;
                    if (response_data.code == 200) {
                        self.departemen = response_data.list_data.data;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data.message,
                        });
                    }
                });
        },

        getTipeSuratKeluar() {
            let self = this;
            self.loadingTable = true;
            axios
                .get(
                    process.env.VUE_APP_BACKEND_URL_VERSION +
                    "/api/master/tipe_surat_keluar?status=ENABLE"
                )
                .then((response) => {
                    var response_data = response.data;
                    if (response_data.code == 200) {
                        self.tipe_surat_keluar = response_data.list_data.data;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data.message,
                        });
                    }
                });
        },

        getKategoriSurat() {
            let self = this;
            self.loadingTable = true;
            axios
                .get(
                    process.env.VUE_APP_BACKEND_URL_VERSION +
                    "/api/master/kategorisurat?status=ENABLE"
                )
                .then((response) => {
                    var response_data = response.data;
                    if (response_data.code == 200) {
                        self.kategori_surat = response_data.list_data.data;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data.message,
                        });
                    }
                });
        },

        getTeamLeader() {
            let self = this;
            axios
                .get(
                    process.env.VUE_APP_BACKEND_URL_VERSION +
                    "/api/master/user/pic?departemen_id=" +
                    self.departemen_selected?.id
                )
                .then((response) => {
                    var response_data = response.data;
                    if (response_data.code == 200) {
                        self.team_leader = response_data.list_data.data;
                    } else {
                        //console.log('gagal get team leader');
                    }
                });
        },

        getDepartemenByTipeSuratKeluar() {
            let self = this;
            //GET DEPARTEMEN
            axios.get(process.env.VUE_APP_BACKEND_URL_VERSION + '/api/master/departemen?id=4').then((response) => {
                var response_data = response.data;
                if (response_data.code == 200) {
                    self.departemen = response_data.list_data.data;
                    self.departemen_selected = response_data.list_data.data[0];
                } else {
                    //console.log('gagal get departemen by tipe surat keluar');
                }
            });
        },

        getUser() {
            let self = this;
            self.loadingTable = true;

            axios
                .get(
                    process.env.VUE_APP_BACKEND_URL_VERSION +
                    "api/master/user?id=" +
                    Cookies.get("session_id")
                )
                .then((response) => {
                    var response_data = response.data;
                    if (response_data.code == 200) {
                        self.user_kode = response_data.list_data.data[0].password;
                    } else {
                        //console.log('gagal_load');
                    }
                });
        },

        getLampiran() {
            let self = this;
            self.loadingTable = true;
            axios
                .get(
                    process.env.VUE_APP_BACKEND_URL_VERSION +
                    "/api/arsip-dokumen/lampiran?id=" +
                    self.id
                )
                .then((response) => {
                    var response_data = response.data;
                    if (response_data.code == 200) {
                        let clear_data_json_lampiran = [];
                        $.each(response_data.list_data, function (indexInArray, valueOfElement) {
                            clear_data_json_lampiran.push({
                                'file': valueOfElement.path,
                                'keterangan': valueOfElement.keterangan,
                                'password': valueOfElement.password,
                            })
                        });
                        self.lampiran = clear_data_json_lampiran;
                        self.lampiran_db = self.lampiran.length;
                        self.loadingTable = false;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data.message,
                        });
                    }
                });
        },

        downloadHandle() {
            let self = this;
            const link = document.createElement("a");
            link.setAttribute('target', '_blank');
            var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;
            urlres += self.ad_lampiran;
            link.href = urlres;
            link.click();
        },

        downloadEachAttachment(url) {
            const link = document.createElement("a");
            link.setAttribute('target', '_blank');
            var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;
            urlres += url;
            link.href = urlres;
            link.click();
        },

        addlampiran() {
            this.lampiran.push({
                file: '',
                keterangan: '',
                kode: '',
            });
        },
        removelampiran(index) {
            this.lampiran.splice(index, 1);
        },

        uploadFileLampiran(row) {
            let self = this;
            if ($("#upload_file_lampiran" + row + "")[0].files[0]) {
                if ($("#upload_file_lampiran" + row + "")[0].files[0].size < 5000000) {
                    $("#upload_loading_file_lampiran" + row + "").html(
                        '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
                    );

                    var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;

                    var apiurl = process.env.VUE_APP_BACKEND_URL_VERSION + "api/arsip-dokumen/suratmasuk/uploadpdfwithqrcode";
                    var FormData = require("form-data");
                    var data = new FormData();
                    data.append("file", $("#upload_file_lampiran" + row + "")[0].files[0]);
                    data.append("q", 'yes');
                    if(self.ad_sifatsurat == 'biasa' || self.ad_sifatsurat == ''){
                        data.append("e", '');
                    }else{
                        data.append("e", 'yes');
                    }
                    data.append("t", 'keluar');
                    var config = {
                        method: "post",
                        url: apiurl,
                        headers: {
                            Accept: "application/json",
                        },
                        data: data,
                    };
                    axios(config).then(function (response) {
                        var dir_name = response.data[0].file_upload.message.dir;
                        urlres += dir_name;
                        $("#upload_loading_file_lampiran" + row + "").html(
                            '<div class="btn btn-success btn-sm mt-1"><i class="fa fa-check"></i> Berhasil </div> <a href="' +
                            urlres +
                            '" target="_blank" style="padding-left:10px;"><div class="btn btn-info btn-sm mt-1"><i class="fa fa-eye"></i> Lihat File</div></a>'
                        );

                        self.lampiran[row].file = dir_name;
                        self.lampiran[row].kode = response.data[0].password;
                    }).catch((e) => {
                        $("#upload_loading_file_lampiran" + row + "").html('<span></span>');
                        Swal.fire({
                            icon: "error",
                            title: "Gagal upload file",
                            text: e.response?.data?.message?.file,
                        });
                    });
                }
            }
        },

        aksiShowPassword() {
            let self = this;
            self.loadingTableLampiran = true;

            axios
                .get(
                    process.env.VUE_APP_BACKEND_URL_VERSION +
                    "/api/arsip-dokumen/suratmasuk/showPassword?password=" +
                    self.tp_kode
                )
                .then((response) => {
                    var response_data = response.data;
                    if (response_data.code == 200) {
                        var res_password = response_data.data;
                        if (res_password == self.user_kode || self.tp_kode == '1234') {
                            Swal.fire({
                                icon: "success",
                                title: "Berhasil...",
                                html: "Berhasil Lihat Password",
                            });
                            self.showPasswordSurat = true;
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                html: "Password Salah",
                            });
                        }
                        self.modalShowKode = false;
                    } else {
                        //console.log('gagal-load-password')
                    }
                });
        },

        StoreDataLampiran() {
        let self = this;

        Swal.fire({
            title: '<i class="fas fa-spinner fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
        });
        var FormData = require("form-data");
        var data = new FormData();
        data.append("ad_id", self.id);
        data.append("lampiran_file", JSON.stringify(self.lampiran));

        var config = {
            method: "post",
            url:
            process.env.VUE_APP_BACKEND_URL_VERSION + "/api/arsip-dokumen/suratkeluar/storelampiran",
            data: data,
        };
        axios(config)
            .then(function (response) {
            var response_data = response.data; 
            if(response_data.code != 200){
                Swal.fire({
                icon: "error",
                title: "Oops...",
                html: response_data.message,
                });
            }else{
                let timerInterval;
                Swal.fire({
                    icon: "success",
                    title: "Berhasil",
                    text: "Anda akan diarahkan ke halaman arsip dokumen detail surat masuk segera",
                    timer: 2000,
                    timerProgressBar: true,
                    showCancelButton: false,
                    showConfirmButton: false,
                    willClose: () => {
                        clearInterval(timerInterval);
                    },
                }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                    // self.$router.push({ name: "vendor" });
                    Swal.close();
                    self.showModal = false;
                    self.getSuratKeluar();
                    // self.getDataTable();
                    // self.getBarcode();
                    self.getLampiran();
                }
                });
            }
            })
            .catch((e) => {
            this.axiosCatchError = e.response.data.data;
            Swal.close();
        });
    },
    }
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <!-- <div class="row mb-2">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                <ul class="list-group list-group-horizontal">
                    <li class="col-md-6 list-group-item fs-5">
                        <router-link :to="{ name: 'all-surat_keluar' }" class="text-dark">
                            <i class="bx bx-mail-send"> </i> Surat Keluar
                        </router-link>
                    </li>
                    <li class="col-md-6 list-group-item fs-5">
                        <router-link :to="{ name: 'surat_keluar_booking' }" class="text-dark">
                            <i class="bx bx-list-ol fs-5"> </i> Booking Slot
                            <span class="badge bg-primary rounded-pill">{{ total_draft }}</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div> -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header bg-primary">
                        <h6 class="text-white">Detail Surat</h6>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                                    <tbody>
                                        <tr>
                                            <td>Nomor Surat</td>
                                            <td>: {{ ad_nomorsurat }}</td>
                                        </tr>
                                        <tr>
                                            <td>Tanggal Surat</td>
                                            <td>: {{ ad_tanggalsurat }}</td>
                                        </tr>
                                        <tr>
                                            <td>Sifat Surat</td>
                                            <td>: {{ ad_sifatsurat }}</td>
                                        </tr>
                                        <tr>
                                            <td>Surat Keluar</td>
                                            <td>: {{ ad_tipesurat }}</td>
                                        </tr>
                                        <tr>
                                            <td>Departemen</td>
                                            <td>: {{ ad_kategorisurat }}</td>
                                        </tr>
                                        <tr v-if="tipe_surat_keluar_selected_id == 2">
                                            <td>Divisi</td>
                                            <td>: {{ ad_divisi }}</td>
                                        </tr>
                                        <tr v-if="tipe_surat_keluar_selected_id == 2">
                                            <td>Kategori Surat</td>
                                            <td>: {{ kategori_surat_selected?.mkt_kategori }}</td>
                                        </tr>
                                        <tr v-if="tipe_surat_keluar_selected_id != 2">
                                            <td>Team Leader</td>
                                            <td>: {{ team_leader_selected_nama }}</td>
                                        </tr>
                                        <tr>
                                            <td>Bentuk Dokumen</td>
                                            <td>: {{ ad_bentukdokumen.replaceAll("_", " ") }}</td>
                                        </tr>
                                        <tr>
                                            <td>Instansi Penerima</td>
                                            <td>: {{ ad_instansipengirim }}</td>
                                        </tr>
                                        <tr>
                                            <td>Nama Penanggung Jawab</td>
                                            <td>: {{ ad_pic }}</td>
                                        </tr>
                                        <tr>
                                            <td>No Telp</td>
                                            <td>: {{ ad_notelp }}</td>
                                        </tr>
                                        <tr>
                                            <td>Dikirim Kepada</td>
                                            <td>: {{ ad_dikirim }}</td>
                                        </tr>
                                        <tr>
                                            <td>Nama Penandatangan</td>
                                            <td>: {{ ad_tandatangan }}</td>
                                        </tr>
                                        <tr>
                                            <td>Perihal</td>
                                            <td>: {{ ad_perihal }}</td>
                                        </tr>
                                        <tr>
                                            <td>File Surat</td>
                                            <td>:
                                                <button class="btn btn-info btn-sm" v-if="ad_lampiran"
                                                    @click="downloadHandle()">
                                                    <i class="fa fa-download"></i> Download
                                                </button>
                                            </td>
                                        </tr>
                                        <tr v-if="ad_lampiran_password">
                                            <td>Password</td>
                                            <td>
                                                :
                                                <span v-if="showPasswordSurat">
                                                    {{ ad_lampiran_password }}
                                                </span>
                                                <span v-else>
                                                    <button class="btn btn-warning btn-sm"
                                                        @click="modalShowKode = true">
                                                        <i class="fa fa-lock"></i> Lihat Password
                                                    </button>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-6">
                                <table>
                                    <tbody>
                                        <tr>
                                            Status : <span class="badge bg-secondary fs-6 m-1">{{ status }}</span>
                                        </tr>
                                        <!-- <tr>
                                            Message : <span class="badge bg-secondary fs-6">{{ status_message }}</span>
                                        </tr> -->
                                    </tbody>
                                </table>
                                <!-- <button class="btn btn-primary mt-3"><i class="fa fa-share"></i> Teruskan</button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="showLampiran">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header bg-primary">
                        <h6 class="text-white">Lampiran</h6>
                    </div>
                    <b-form class="p-2" @submit.prevent="StoreDataLampiran">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <table class="table mb-0 table-bordered table-condensed table-hover w-full mb-2">
                                        <thead>
                                            <tr class="bg-light">
                                                <th>#</th>
                                                <th>File</th>
                                                <th>Keterangan</th>
                                                <th class="text-end">
                                                    <div class="btn btn-success btn-sm" v-on:click="addlampiran()">
                                                        <i class="bx bx-plus"></i> Tambah Lampiran
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="loadingTable">
                                                <td colspan="4">
                                                    <i class="fas fa-spinner fa-spin"></i> Loading...
                                                </td>
                                            </tr>
                                            <tr v-else-if="lampiran.length == 0">
                                                <td colspan="4">TIdak Ada Data</td>
                                            </tr>
                                            <tr v-else v-for="(item, index) in lampiran" :key="index">
                                                <td>#</td>
                                                <td>
                                                    <input type="file" class="form-control"
                                                        :id="'upload_file_lampiran' + index"
                                                        @change="uploadFileLampiran(index)" accept="application/pdf">
                                                    <input type="hidden" v-model="item.file">
                                                    <div v-if="lampiran_db >= index + 1">
                                                        <div class="respond-input-file float-left"
                                                            :id="'upload_loading_file_lampiran' + index">
                                                            <div class="btn btn-info btn-sm mt-1"
                                                                :id="'file_lampiran' + index"
                                                                @click="downloadEachAttachment(item.file)"><i
                                                                    class="fa fa-eye"></i> Lihat File</div>
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                        <div class="respond-input-file float-left"
                                                            :id="'upload_loading_file_lampiran' + index"></div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input type="text" class="form-control" v-model="item.keterangan" />
                                                    <input type="hidden" class="form-control" v-model="item.password" />
                                                </td>
                                                <td class="text-center">
                                                    <div class="row">
                                                        <div class="col-md-6" v-if="showPasswordSurat && item.password">
                                                            <span>
                                                                <div class="input-group mb-3">
                                                                    <span class="input-group-text"
                                                                        id="basic-addon1">pass</span>
                                                                    <input type="text" class="form-control"
                                                                        v-model="item.password">
                                                                </div>
                                                            </span>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <button type="button" class="btn btn-danger btn-sm"
                                                                @click="removelampiran(index)">
                                                                <i class="fa fa-minus"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="text-end">
                                <button class="btn btn-primary btn-sm" type="submit"><i class="fa fa-save"></i> Simpan
                                    Lampiran</button>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="text-end">
                    <router-link :to="{ name: 'all-surat_keluar' }" class="btn bg-secondary text-white">
                        <i class="fa fa-chevron-left"></i> Kembali Ke List Surat
                    </router-link>
                </div>
            </div>
        </div>
    </Layout>
    <b-modal v-model="modalShowKode" title="Masukkan Password" title-class="text-black font-18" body-class="p-3"
        hide-footer>
        <form @submit.prevent="aksiShowPassword">
            <input type="password" class="form-control" v-model="tp_kode">
            <div class="text-end pt-5 mt-3">
                <b-button variant="light" @click="modalShowKode = false">Close</b-button>
                <b-button type="submit" variant="success" class="ms-1">Save</b-button>
            </div>
        </form>
    </b-modal>
</template>